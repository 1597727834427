import {useState, useEffect} from 'react';

import {DeleteForeverOutlined} from '@mui/icons-material';
import {Grid, Avatar, DialogContentText, Typography, DialogContent, DialogActions, useTheme} from '@mui/material';

import useWorkspaces from '../../hooks/providers/useWorkspaces';
import {workspaceShape} from '../../proptypes-shapes';
import Button from '../form/buttons/Button';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';

// eslint-disable-next-line complexity
const DeleteWorkspaceOrReportModal = ({workspace}) => {
  const {
    deleteWorkspace,
    deleteReport,
    selectedWorkspaceId,
    setDeleteWorkspaceOrReportModalOpen,
    deleteWorkspaceOrReportModalOpen: open,
    rightClickedReportTab: report,
    setAnchorElReportTabsMenu,
    setRightClickedReportTab,
    closeWorkspaceTabsMenu
  } = useWorkspaces();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const isReportDeletion = report && !workspace;

  const modalTitle = `Supprimer ${isReportDeletion ? 'le rapport' : "l'espace"}`;
  const successfulDeletionSentence = isReportDeletion ? 'Votre rapport a bien été supprimé !' : 'Votre espace de travail a bien été supprimé !';
  const confirmationSentence = isReportDeletion ? 'Êtes-vous sûr de vouloir supprimer définitivement ce rapport ?' : 'Êtes-vous sûr de vouloir supprimer définitivement cet espace ?';
  const spinnerSentence = isReportDeletion ? 'Suppression du rapport en cours' : "Suppression de l'espace en cours";

  const theme = useTheme();

  const onClose = () => {
    closeWorkspaceTabsMenu();
    setAnchorElReportTabsMenu(null);
    setRightClickedReportTab(null);
    setDeleteWorkspaceOrReportModalOpen(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    const result = isReportDeletion ? await deleteReport(selectedWorkspaceId, report.report_id) : await deleteWorkspace(workspace.workspace_id);
    setIsSubmitting(false);

    if (result.status === 200) {
      setHasBeenDeleted(true);
    }
  };

  useEffect(() => {
    if (open) {
      setHasBeenDeleted(false);
    }
  }, [open]);

  return (
    <BaseModal
      title={modalTitle}
      maxWidth="sm"
      onClose={(event, reason) => {
        // Prevent user from closing modal by clicking away while workspace is deleting
        // If modal is closed before deletion is done, he won't see modal displaying success deletion message
        if (reason === 'backdropClick' && isSubmitting) {
          return;
        }
        onClose();
      }}
      open={open}
    >
      <DialogContent sx={{py: 3}}>
        {hasBeenDeleted && (
          <Grid container direction="column" alignItems="center">
            <Avatar sx={{p: 5, bgcolor: `${theme.palette.error.light}30`}}>
              <DeleteForeverOutlined sx={{fontSize: 60}} color="error" />
            </Avatar>
            <Typography sx={{mt: 5, mb: 1, fontSize: 15}} variant="inherit">
              {successfulDeletionSentence}
            </Typography>
          </Grid>
        )}
        {!isSubmitting && !hasBeenDeleted && <DialogContentText variant="inherit">{confirmationSentence}</DialogContentText>}
        {isSubmitting && <Spinner text={spinnerSentence} isLoading />}
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 2}}>
        {!isSubmitting && (
          <Button variant="outlined" color="primary" onClick={onClose}>
            {hasBeenDeleted ? 'Fermer' : 'Annuler'}
          </Button>
        )}
        {!hasBeenDeleted && (
          <Button disabled={isSubmitting} variant="contained" color="error" onClick={handleDelete}>
            Supprimer
          </Button>
        )}
      </DialogActions>
    </BaseModal>
  );
};

DeleteWorkspaceOrReportModal.defaultProps = {
  workspace: null
};

DeleteWorkspaceOrReportModal.propTypes = {
  workspace: workspaceShape
};

export default DeleteWorkspaceOrReportModal;
